@import "../../css-config/mixins.scss";

.main-servicerNavBar {

  flex-direction: column;
  height: 100%;
  background-color: #F3F6FD;
  // border: 2px solid red;
  border-right: 2px solid F3F6FD;
  box-shadow: #F3F6FD;

  .main-contianer-servicerNavBar {
    display: flex;
    // justify-content: center;
    // height: 100%;
    border-right: 2px solid F3F6FD;
    padding: 0;
    margin: 0;
    // background-color: #F3F6FD;
    z-index: 100;

    .serviceAvenger-icon-image {}

  }
}

.servicer-name {
  padding: 0.5rem 1.3rem;
  display: flex;
  justify-content: center;
  // border: 1px solid red;
}

.servicer-name-button {
  border: 1px solid red;

}

@media (min-width: 200px) and (max-width:767px) {

  .main-servicerNavBar {
    // display: none;
  }

  .servicer-name {
    padding: 1.2rem 1rem;
    display: flex;
    justify-content: center;
    // border: 1px solid red;
  }

  .servicer-name-button {

    //   width: 2rem;
    //  margin-left: 0.2rem;
    .servicer-navbar-title {
      display: none;
      // border: #0f1219;
    }
  }



}

// @media (max-width:426px) {
//   .main-contianer-servicerNavBar{
//   .main-container-avatar{
//     border: '1px solid red';
//     height:"70px"; 
//      width:"70px";

//     img.serviceAvenger-icon-image{
//       height:"70px"; 
//       width:"70px";
//     }

// }
//   }
// }

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .main-container-avatar {
    .image-container {
      img.serviceAvenger-icon-image {
        height: 65px;
        width: 65px;
      }
    }
  }
}