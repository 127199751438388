@import '../../../css-config/mixins.scss';


// .grid-container{



//   .table-cell{
//     border: 1px solid red;

//   }
// }

@media only screen and (min-width:200px) and (max-width:767px) {

  .grid-container {


    .table-cell {
      display: none;
    }
  }

}