@import "../../css-config/mixins.scss";


.nav-item{
    
 
    cursor: pointer;
    
    .sub-element{
        padding: 0px 15px;
        display: flex;
        align-items: center;
        color: skyblue;
        

        &:hover{
            color: white;
        }
        
        
    }
    
    

    .subMenu-container{
        position: absolute;
        top: 100%;
        z-index: 999999;
   
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        @include lg{
            position: relative!important;;
        }
    }
}
    
           
    
        
