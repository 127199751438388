@import "../../css-config/mixins.scss";

.preview-paper {
    display: flex;
    align-items: center;
    padding: 10px 30px;
    height: 70px;
    border: 2px solid;
    border-color: #e9e9e9;
    box-shadow: none;


    @include lg {
        padding: 12px 18px;
        width: 15rem;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1270px) {
        padding: 30px;
    }

    // @include xl {
    //     padding: 30px;
    // }

    .preview-text-content {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        // gap: 5px;
    }
}