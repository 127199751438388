@mixin sm {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin lg {
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    @content;
  }
}

@mixin xl {
  @media only screen and (min-width: 1024px) and (max-width: 1599px) {
    @content;
  }
}

@mixin xxl {
  @media only screen and (min-width: 1600px) {
    @content;
  }
}