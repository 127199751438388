
@import "../../css-config/mixins.scss";

.accept-reject-btn-container{
    display: flex;
    gap: 10px;
    // @media only screen and (min-width: 768px) and (max-width: 1023px) {
    //     display: flex;
    //     flex-direction: column;
    //   }
    @include lg {
        display: flex;
        flex-direction: column;
    }
}


.successbtn {
    button {
        margin-top: 10px;
        padding: 4px 4px;
        height: 70%;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #009ABE;
        font-size: 0.9rem;
        cursor: pointer;
        border-radius: 6px;
        outline: none;
        transition: background-color 0.4s;
        box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5);
        border-color: #009ABE;
        border-width: 2px;
        border-style: solid;
        text-transform: capitalize;
    }
   
    @include lg{
        .accept-btn{
            display: none;
        }
    }
    i {
        width: 1em;
        height: 0.5em;
        padding: 0.4em;
        background-color: #fff;
        color: black;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.8em;
        font-size: 0.8em;
        border-color: #009ABE;
        border-style: solid;
        border-width: 2px;
    }
    
}

.rejectbtn {
    button {
        margin-top: 10px;
        padding: 4px 4px;
        height: 70%;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #E56A54;
        font-size: 0.9rem;
        cursor: pointer;
        border-radius: 6px;
        outline: none;
        transition: background-color 0.4s;
        box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5);
        border-color: #E56A54;
        border-width: 2px;
        border-style: solid;
        text-transform: capitalize;
    }

    @include lg{
        .reject-btn{
            display: none;
        }
    }

    i {
        width: 1em;
        height: 0.5em;
        padding: 0.4em;
        background-color: #fff;
        color: black;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.8em;
        font-size: 0.8em;
        border-color: #E56A54;
        border-style: solid;
        border-width: 2px;
    }
}

.my-job-topHeading {
    color: #02A0FC;
    margin-top: 0;
    padding-top: 0;
}

.my-job-subTitle-table { 
    color: #233849;
    font-weight: 600;
}

.MuiTableCell-root {
    vertical-align: top;
}