@import "../../css-config/mixins.scss";

.servicer {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.servicerContainer {
    // padding: 5px 0px 10px 0px;

    // @include xxl {
    //     padding-top: 40px;
    //     padding-bottom: 40px;
    //     padding-left: 32px;
    //     // padding-right: 15%
    // }

    p {
        color: var(--color-neutral-general-body-color, #757C80);
        /* Body/Medium/body.small */
        font-family: Roboto;
        font-size: 13.17px;
        font-style: normal;
        font-weight: 400;
    }

    b {
        color: #000;
        font-family: Roboto;
    }

    flex-direction: column;
    gap: 32px;
    border-radius: 8px;
    background: var(--color-neutral-00, #FFF);
}

.Search-Container {
    border-radius: 3px;
    border: 1px solid var(--color-neutral-60, #AEB5BC);
    padding: 12px 20px 12px 20px;
    margin-top: 5px;

    .searchInputs {
        display: flex;
        align-items: center;

        input {
            @include lg {
                display: none;
            }

            background-color: transparent;
            border: none;
            color: #AEB5BC;
            width: 100%;
        }

        SearchIcon {
            @include lg {
                height: 20px;
                width: 20px;
            }
        }

    }
}

.header {
    th {
        background-color: #F2F4FF;
        color: black !important;
    }
}

.filterIcon {
    padding: 6px;
    border-radius: 8px;
    background: var(--color-light-05, rgba(0, 115, 204, 0.23));
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    box-shadow: none !important;
}

.borderLine {
    border-left: 2px solid #AEB5BC;
    ;
    height: 50px;
    padding: 2px;
}

.account {
    display: flex;
    padding: 40px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    flex: 1 0 0;
    border-radius: 8px;
    background: var(--color-neutral-00, #FFF);

    .form-container {
        label {
            color: var(--color-neutral-general-headings-color, #222529);
            font-size: 14px;
            font-family: DM Sans;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
            margin-bottom: 8px;
        }

        filter {
            display: flex;
            padding: 8px;
            align-items: flex-start;
            gap: 10px;
            border-radius: 8px;
            background: var(--color-light-05, rgba(0, 115, 204, 0.23));
        }

        em {
            color: lightgray;
        }


    }

    .MuiTextField-root {
        margin-top: 8px;
    }


}

.button-search {
    border-radius: 5px;
    padding: 0px 10px;

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        min-width: 100%;
        padding: 0px 10px !important;
    }

    // @media only screen and (min-width: 1025px) and (max-width: 1440px) {
    //     padding: 8px 26px;
    //     min-width: 100%;
    // }
    @media screen and (width:1440px) {
        padding: 8px 26px;
        min-width: 100%;

    }

    // @media screen and (width:2560px) {
    //     padding: 8px 26px;
    //     min-width: 100%;
    //     margin: 0rem 2rem;

    // }
}

.button-reset {
    padding: 8px 17px !important;
    border-radius: 5px;

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        padding: 8px 10px !important;
        min-width: 100% !important;
    }

    @media screen and (width:1440px) {
        padding: 8px 27px !important;
        min-width: 100% !important;

    }

    @media screen and (width:1366px) {
        padding: 8px 24px !important;
        min-width: 100% !important;

    }

    // @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    //     padding: 8px 27px !important;
    //     min-width: 100% !important;
    // }


}