@import "../../css-config/mixins.scss";

.parent-paper {
  margin-bottom: 2rem;
  padding: 1rem;
  // max-width: 91vh;

  // max-height:70vh; 
  // box-sizing: border-box;
  @include lg {
    // max-width: 81vh;
    padding: 0px;
  }

  @include xl {
    // max-height: 80vh;

  }

  @include xxl {}

  .main-class {

    display: flex;
    width: 100%;
    // height: 80vh;
    padding: 0px;
    justify-content: space-around;
    align-items: center;

    // @include lg {
    //   flex-direction: column;
    //   //  max-height: 42vh;
    // }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      flex-direction: column;
    }


    .left-section {
      display: flex;
      width: 35%;
      align-items: center;
      position: relative;
      justify-content: center;

      .doughnut-text {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        // @include lg{
        //   font-size: 10px;
        // }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
          font-size: 10px;
        } 
      }

      @include lg {
        align-self: center;
        padding-bottom: 1rem;
      }
    }

    .right-section {
      display: flex;
      box-sizing: border-box;

      .table-styling {
        max-height: 75vh;

        .table-styling-cell {
          @include xl {}

          @include xxl {}
        }
        .table-cell{
          display: flex;
          gap: 5px;
          align-items: center;
          @include lg{
            display: flex;
            flex-direction: column;
            // align-items: flex-start;
            font-size: 10px;
          }
        }
        

        //    @include lg{
        //      max-height: 50vh;
        //    }
        //    @include xl{
        //      max-height: 30vh;
        //    }
        //    @include xxl{
        //    max-height: 20vh;
        //    }
      }


    }

  }
}

.btn_styling {
  background: white !important;
}

.btn_styling_selected {
  background: rgb(1, 112, 176) !important;
}