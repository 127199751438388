@import "../../css-config/mixins.scss";

.data-display {
    display: flex;
    box-sizing: border-box;
    // margin-top: 30px;
    margin-bottom: 50px;
    justify-content: space-between;
    // align-items: center;
    padding: 10px;

    @include lg {
        // padding: 0px;
        flex-direction: column;
        padding: 25px 22px;
    }

    // gap: 10px;

    .left-ranking {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 10px 0px 10px 10px;

        // width: 30%;
        @include lg {
            // border: 2px solid;
            padding: 0px;
            // width: 17rem;
            flex-direction: row;
            flex-wrap: wrap;
        }
        @media only screen and (max-width: 768px) {
            gap: 10px;
        }
    }

    .center-ranking {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 40%;

        @include lg {
            // width: 100%;
            margin-left: 10rem;
        }
       

        .doughnut-text {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .right-ranking {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 10px 10px 10px 0px;

        // width: 30%;
        @media only screen and (max-width: 768px) {
            gap: 10px;
            padding-bottom: 10px;
            
        }

        @include lg {
            // border: 2px solid;
            padding: 0px;
            flex-direction: row;
            flex-wrap: wrap;
        }
        
    }
}