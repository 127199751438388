@import "../../css-config/mixins.scss";


.Navbar {

  display: flex;
  justify-content: center;
  align-items: center;
  background: #233849;
  box-shadow: 0px 4px 44px 0px #00000012;
  font-size: 14px;
  height: 8vh;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;


  @include lg {
    padding: 0px 16px 0px 37px;
    align-items: flex-start;
    flex-direction: column;
    height: auto;

  }

  @include xl {
    height: 8vh;
  }

  @include xxl {
    height: 6vh;
  }

  .hamburger {
    display: none;

    @include lg {
      display: flex;
      align-items: center;
      color: #AEB5BC;
      height: 64px;
    }
  }

  .nav-list {
    display: none;

  }

  .nav-listactive {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // gap: 24px;
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: flex-start;
    // color:#AEB5BC;
    margin-top: 5px;
    margin-bottom: 5px;

    @include lg {
      // display: block;
    }

  }

  .nav-list-desk {
    display: flex;
    justify-content: center;
    // gap: 24px;
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: center;
    // color:  #AEB5BC;
    height: 64px;

    @include lg {
      display: none;

    }
  }

}

.Navbar .subMenu-container:first-child {
  border: 1px solid #ccc;
}