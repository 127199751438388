@import "./css-config/mixins.scss";
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');



.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  padding: 0;
  margin: 0;
 // background: #FCFCFF;
  font-family: Poppins;
  // font-family: Roboto;

  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  height: 100%;

.boxContainerServicer{
  width: 100%;
  min-height:100vh;
}

  .boxContainer {
    width: 80%;

    // @include lg {
    //   width: 90%;
    // }

    // @include xl {
    //   width: 90%;
    // }
  }
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: rgb(2, 160, 252) !important;
  color: #fff !important;
  border: none !important;
}

.env {
  position: fixed;
  top: 90vh;
  background: black;
  color: #fff;
  padding: 5px;
  border-radius: 10px 0 0 10px;
  right: 0;
  z-index: 1000;
}

em {
  font-style: inherit !important;
}
.navbar-container {
  width:100%;
  /* Initial styles for the Navbar */
}

.navbar-container.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  /* Add other styles for sticky navbar */
}

.servicer-navbar{
  display: flex;
}

@media only  screen and (min-width:200px) and (max-width:767px) {
  .servicer-navbar{
    display: none;
  }
}