@import "../css-config/mixins.scss";

.compContainer{
    display: flex;
    padding: 40px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    // flex: 1 0 0;
    // border-radius: 8px;
    background: var(--color-neutral-00, #FFF);
    
    .form-container{
        width: 100%;
        .header {
            th {
                background-color: #233849;
                color: #FFF !important;
            }
        }
        label{
            // color: var(--color-neutral-general-headings-color, #AEB5BC);
    
        //     /* Generals/Input Labels/Medium */
        //     font-size: 14px;
        //     // font-family: DM Sans;
        //     font-family: Poppins;
        //     font-style: normal;
        //     // font-weight: 700;
        //     line-height: normal;
        //     text-transform: capitalize;
        // }
        em{
            color: lightgray;
            font-style: normal;
        }  
        .form{
            width: 100%;
            display: flex;
            gap: 50px;
            .left{
                width: 40%;
            }
            .right{
                width: 60%;
                height: 380px;
            }
            .file-card {
                padding: 1em;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border: 3px dashed #cbd5e0;
                background-color: #edf2f7;
                min-height: 230px;
            
                .file-inputs {
                    position: relative;
                    margin-bottom: 1.5em;
            
                    input {
                        position: relative;
                        text-align: right;
                        // border-color: #027AC0;
                        opacity: 0;
                        z-index: 2;
                        cursor: pointer;
                        height: 46px;
                        max-width: 150px;
                    }
            
                    button {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: black;
                        font-size: 1.1rem;
                        cursor: pointer;
                        border-radius: 4px;
                        outline: none;
                        transition: background-color 0.4s;
                        box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5);
                        border-color: #02A0FC;
                        border-width: 2px;
                        border-style: solid;            
                        i {
                            width: 1.5em;
                            height: 1.5em;
                            padding: 0.4em;
                            background-color: #fff;
                            color: black;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 0.8em;
                            font-size: 0.8em;
                            border-color: #02A0FC;
                            border-style: solid;            
                            border-width: 2px;
                        }
                    }
                }

                .file-container{
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                }
                .file-text{
                    font-size: small;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    width: 100%;
                    p{
                        flex-grow: 1;
                    }
                }
            }
        }

        .first-section{
            width: 100%;
            display: flex;
            gap: 50px;
            .left-section{
                width: 50%;
                padding-top: 40px;
            }
            .right-section{
                padding-top: 40px;
                width: 50%;
            }
        }
        .bottom-section{
            width: 40%;
        }
        
    }
    .MuiTypography-root{
    
        /* Generals/Input Labels/Medium */
        font-size: 14px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        margin-bottom: 8px;
    }
}
}