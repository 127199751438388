.main-login {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  .login-logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.main-box {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  width: 50%;
  // margin-left: 15rem;


  .main-box-content {

    display: flex;
    // justify-content: center;
  }
}